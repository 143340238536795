.iconDiv{
    margin: 10px;
}
.sidepaneDiv{
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
    justify-content: center;
}

.categoryName{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: 30px;
}

