.incident-table {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    gap: 30px;
    font-family: Arial, sans-serif;
    margin: 20px;
    color: #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    min-height: 70vh;
  }
  
  .incident-table h2 {
    color: #ff7f0e;
    margin-bottom: 20px;
  }
  
  .react-tabulator {
    margin-top: 20px;
  }
  
  .tabulator {
    background-color: #000000;
    color: #e0e0e0;
    width: 100%;
  }
  
  .tabulator-header {
    background-color: #555;
  }
  
  .tabulator-header .tabulator-col {
    background-color: #555;
    color: #e0e0e0;
  }
  
  .tabulator-row {
    background-color: #000000;
    color: #e0e0e0;
  }
  
  .tabulator .tabulator-cell {
    position: relative;
  }
  
  .tabulator .tabulator-cell .tabulator-edit-select {
    width: 100%;
    height: 100%;
    padding: 4px;
    margin: 0;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: inherit;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  
  /* .tabulator-row:nth-child(even) {
      background-color: #f79009;
      color: black;
  } */
  
  /* .tabulator-row:hover {
      background-color: #666;
  } */
  
  .tabulator-row.tabulator-selectable:hover {
    background-color: #000000;
    cursor: pointer; }
  
  .tabulator-col-title {
      color: #f79009;
  }
  
  .tabulator-cell {
    border: 1px solid #555;
    color: #f79009;
    text-align: center;
  }
  
  .tabulator-row .expander {
    background-color: #212121;
    color: #fff;
    border-top: 1px solid #666;
    padding: 10px;
  }
  
  button {
    background-color: #666;
    color: #e0e0e0;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
  }
  
  .imageClusterDiv{
    display:flex;
    flex-direction:row;
    justify-content:center;
    gap:50px;
    align-items:center;
    margin-bottom:40px
  }
  
  .originalImageDiv{
    display:flex;
    flex-direction:column;
    gap:20px;
    align-items:center
  }
  
  .duplicateImagesDiv{
    display:flex;
    flex-direction:column;
    gap:20px;
    align-items:center;
  }
  
  .duplicateImageView{
    display:flex;
    flex-direction:row;
    gap:20px;
  }
  
  .imageCluster {
    background: dimgrey;
    width: 100px;
    height: 100px;
    border-radius: 10px
  }
  
  .mergeImagesButtonDiv{
    display:flex;
    flex-direction:row;
    justify-content:center;
  }
  
  .actionButton {
    background : #f79009;
    color: black;
  }
  
  .actionButton:hover {
    background : #eeece9;
    color : black;
  }
  
  .expandInfoDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 10px
  }
  
  .updateStatus {
    display:flex;
    flex-direction:row;
    gap:10px;
    justify-content:center;
    align-items:center
  }
  
  #taskType{
    border-radius:10px;
    width:100%;
    background:dimgrey;
    color:#f79009;
    padding:10px
  }
  
  .paginationDiv {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
  }

  .pageNumbersDiv{
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow: scroll;
    max-width:50vw;
  }

  .pageNumbersText{
    border-radius: 50%;
    padding: 5px 10px;
  }

  .pageNumberInput{
    background: grey;
    width: 3vw;
    border-radius: 10px;
    text-align: center;
  }

  .noImagesDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    padding: 80px;
  }

  .noImageText{
    font-size: 30px;
    font-weight: 700;
  }

  .pageClicked{
    background: #fff;
  }

  .pageNotClicked{
    background: #f79009;
  }

  .imagesSection{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loader {
    border: 10px solid #f3f3f3; 
    border-top: 10px solid #f79009; 
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .calculateThresholdDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  /* button:hover {
    background-color: #777;
  } */
  