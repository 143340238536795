.pointInfoDetailDiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    overflow: scroll;
    overflow-wrap: break-word;
}

.categoryDiv{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: 30px;
}