.div1Style{
    width: '100%';
    display: 'flex';
    flexDirection: 'column';
    alignItems: 'center';
} 

.div2Style{
    width: '100%';
    marginTop: '-110px';
    display: 'flex';
    flexDirection: 'column';
    alignItems: 'center';
}

.passwordValidationDiv{
    display: 'flex';
    flexDirection: 'column';
    alignItems: 'flex-start';
    marginBottom: '15px';
}

.validationDiv{
    display: 'flex';
    alignItems: 'center';
}