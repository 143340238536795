.uploadDatasetsDiv {
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
}

.uploadTypeDiv {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.uploadTypeText {
    text-align: "center"
}

.handleS3IntegrationDiv {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputText {
    border-radius: 10px;
    width: 100%;
    background: dimgrey;
    color: #f79009;
    padding: 10px;
}

.uploadDatasetsImage{
    height: 80px;
}

.taskTypeDiv {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

#taskType {
    border-radius: 10px;
    width: 100%;
    background: dimgrey;
    color: #f79009;
    padding: 10px
}