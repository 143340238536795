.organizationProfileDiv{
    min-height: 80vh;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 40px 0px 40px;
}

.planDetailsDiv{
    margin: 30px 0px 40px 0px;
}

.pageHeader{
    color: #f79009;
    font-weight:bold;
    text-align: center; 
}

.premiumPlanDetailsDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.dataCapacityDetailsDiv{
    text-align: left;
}

.organizationDetails{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.datasetDetailsDiv{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    min-height:'max-content';
    /* height: 50vh; */
    overflow: scroll;
}

.datasetDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    justify-content: center;
    min-width:300px;
    background-color: rgb(67, 69, 74);
    border-radius: 10px;
    padding: 10px;
}

.premiumSubscribeLinkDiv{
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 30px;
}

.linkStyle{
    color:#f79009;
    cursor: pointer;
}


