.passwordValidationDiv{
    display: 'flex';
    flexDirection: 'column';
    alignItems: 'flex-start';
    marginBottom: '15px';
}

.validationDiv{
    display: 'flex';
    alignItems: 'center';
}