.enlargedViewDiv
{
    display:flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.closeIconDiv{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
}

.imageDetailsDiv{
    margin:40px;
    display: flex;
    flex-direction: row;
    width: 70%;
    align-self: center;
    height: 80vh;
    overflow: scroll;
    gap: 10px;
}

.detailsDiv{
    background: black;
    padding: 10px 20px 20px 20px;
    border-radius: 10px;
}

.toggleImageDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    width: 100%;
}