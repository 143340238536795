.mainSection{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.calculateScoresSection{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.analyticsSection{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    padding: 20px;
}

.analyticsCard{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
    border:1px solid slategrey;
    width: max-content;
}

.analyticsInfoSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.analyticsInfoHeader{
    text-align: center;
    color: floralwhite;
    font-weight: bold;
}

.analyticsPreloader .analyticsInfoImage{
    display: flex;
    flex-direction: row;
    justify-content: center; 
}

.analyticsInfoValue{
    color: floralwhite;
    font-size: 20px;
    font-weight: 700;
}

.analyticsInfoIcon{
    display: flex;
    flex-direction: column;
    align-items:flex-end;
}
.analyticsIcon1{
    justify-content:flex-start;
}

.analyticsIcon2{
    justify-content:space-between;
}

.graphToggleInput{
    display: flex;
    flex: content;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 20px 0px 20px;
}

.handleScoreDetailsDiv{
    display:flex;
    flex-direction:column;
    gap:10px;
}

.handleScoreInput{
    border-radius:10px;
    width:100%;
    background:dimgrey;
    color:#f79009;
    padding:10px
}

.imageTypeInput{
    display:flex;
    flex-direction:row;
    gap:10px;
    justify-content:center;
    align-items:center;
}
