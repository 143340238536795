.mainDiv{
    display:flex;
    flex-direction:row;
    gap:10px;
    margin:20px;
}

.toggleSelect{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px;
}

.filtersSection{
    display: flex;
    flex-direction: column;
}

.filtersNav{
    display: flex;
    flex-direction: column;
}

.filtersDisplay {
    display: flex;
    flex-direction: column;
    gap: '10px';
    height: '90vh';
    overflow: 'auto'
}

.searchFilterInput{
    background-color: dimgrey;
    color: white;
    width: max-content;
    border-radius: 10px;
}

.resetFiltersButton{
    width: max-content;
}

.noResultDiv{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.filtersDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 90vh;
    margin-top: 10px;
    overflow: auto;
}

.listFiltersDiv{
    width: 200px;
}


.categoryTypeHeader{
    color: #f79009;
    margin-top: 10px;
    font-weight:700;
}

.plotDiv{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 80vw;
    height: 100%;
}

.pointInfoDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20vw;
}

.noPointInfoDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.noDataPointsDiv{
    width: 100vw;
    height: 100%;
}

.preloader{
    width: 70vw;
}
@media(max-width:700px)
{
    .filtersDisplay {
        display: flex;
        flex-direction: row;
    }
}