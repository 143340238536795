body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.container-main {
    /* padding-top: 63px; */
    background: black;
}

.footer {
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    height: max-content;
    padding: 20px;
    color:rgb(153, 151, 151)
    /* background: #F79009; */
}

.banner {
    margin-top: 20px;
    background: transparent;
    border-bottom: 1px solid #0414341A;
    font-family: 'Space Mono';
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}

.nav-dash {
    color: #DC7F06;
}

.nav-company {
    color: #DC7F06;
}

.heading {
    color: white;
}


.heading-title {
    /* font-size: 40px; */
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.022em;
    text-align: center;
}

.heading-content {
    /* font-size: 24px; */
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.019em;
    text-align: center;
}

.join_btn {
    /* height: 43.36px; */
    /* padding: 11px, 24 .64px, 11.36px, 25px; */
    border-radius: 10px;
    background: #F79009;
    padding: 1.0rem;
    width: max-content;
}


.vector-border {
    background: #0E1116;
}

.gradient-border {
    padding: 1.5px;
    background: linear-gradient(to left, #F79009, #FFFFFF);
}

.sample-header {
    border-bottom: 1px solid gray;
}

.bad-cars {
    border: 1px solid #F9F8F0;
}

.data-title {
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.022em;
    text-align: center;
}

.data-description {
    padding: 0px, 1.88px, 1.79px, 4.08px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.019em;
    text-align: center;
}

.meta {
    background: #0E1116;
}

.meta-item {
    color: #F9F8F0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
}

.meta-item-header {
    color: #A7A7A7;
}

.class-date {
    color: #8B949E;
}