.workflow{
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-height: 70vh;
    margin-bottom: 60px;
}

.workflowHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
}

.availableWorkflows{
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin-left: 20px;
}

.workflowKey{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.workflows{
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 20px;
    width: 90vw;
    overflow: scroll;
}

.workflowSet{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: black;
    color: #f79009;
    border: 2px solid #f79009; 
    padding: 20px;
    cursor: pointer;
}
#addWorkflowInput{
    border-radius: 10px;
    width: max-content;
    text-align: center;
    align-self: flex-end;
}

#updateWorkflowInput{
    display: none;
}

.workflowRoute{ 
    display: flex;
    flex-direction: row;
    gap: 10px; 
    cursor: pointer;
}