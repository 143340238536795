.searchFilterDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin:20px
}

.searchFilterInput{
    background-color: dimgrey;
    color: white;
    width: max-content;
    border-radius: 10px;
    padding-left: 10px;
}

.filterNavbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px;
    position: sticky;
    height: 40px;
}

.imageFilterNavbar{
    /* display: flex; */
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 20px;
    height: 20vh;
    overflow: scroll;
    flex-wrap: wrap;
    
}

.pageNumberFilterInput{
    background-color: dimgrey;
    color: white;
    width: max-content;
    border-radius: 10px;
    height: max-content;
    padding-left: 10px;
}

.mainDiv{
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* padding: 20px; */
}

.filtersDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 90vh;
    width: 200px;
    overflow: auto;
}

.noFiltersDiv{
    display: flex;
    flex-direction: row;
}

.listFiltersDiv{
    width: 200px;
}

.categoryTypeHeader{
    color: #f79009;
    margin-top: 10px;
    font-weight: 700
}

.preloader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90vw;
}

.tagImagesDiv{
    display:flex;
    flex-direction:column;
    gap:10px;
}

.tagInput , #newCategory{
    border-radius:10px;
    width:100%;
    background:dimgrey;
    color:#f79009;
    padding:10px  
}

#imageCategoryInput{
    display: none;
}

.inputText {
    border-radius: 10px;
    width: 100%;
    background: dimgrey;
    color: #f79009;
    padding: 10px;
}

.inputRange{
    color:#f79009
}

.similarImagesDiv{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
