.filepond--root {
    --color-background: #2b2b2b;
    --color-panel-background: #3a3a3a;
    --color-panel-background-hover: #444;
    --color-panel-invert: #ffffff;
    --color-overlay-background: rgba(0, 0, 0, 0.8);
    --color-text: #ffffff;
    --color-text-sub: #d1d1d1;
    --color-action: #4caf50;
    --color-action-hover: #66bb6a;
    --color-icon: #ffffff;
    --color-success: #4caf50;
    --color-warning: #ff9800;
    --color-error: #f44336;
  }

.thresholdValueDiv{
    display:flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.snapshotDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.taskDiv{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: 30px;
}

.downloadDiv{
    justify-content: flex-end; 
}

.actionButtonStyle :hover{
    background: rgb(28, 35, 35);
    border-radius: 10px;
    margin: 3px;
    padding: 3px;
    cursor: pointer;
}

.actionButtonStyle {
    background: #000
}


.disabledActionButton {
    background: #000;
    cursor:not-allowed;
}

.disabledActionButton :hover{
    background: rgb(44, 26, 47);
    border-radius: 10px;
    margin: 3px;
    padding: 3px;
}

.fileButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #000;
}

.uploadFileDiv{
    background-color: #000;
}

.uploadSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.deleteDatasetButton{
    background: transparent;
}

.deleteDatasetButton :hover{
    background: rgb(156, 202, 202);
    padding: 10px;
    border-radius: 10px;
}